/* eslint no-console:0 */

import "regenerator-runtime/runtime";
import Rails from "@rails/ujs";
import React from "react";
import { createRoot } from "react-dom/client";
import App from "../components/admin_v2/App.jsx";
import "../lib/message-bus-init.js";

const loadApp = () => {
  const rootElement = document.getElementById("dashboard-app");
  Rails.start();

  if (rootElement) {
    const props = JSON.parse(rootElement.dataset.props || "{}");
    const root = createRoot(rootElement);
    root.render(<App {...props} />);
  }
};

if (document.readyState === "complete") {
  loadApp();
} else {
  document.addEventListener("DOMContentLoaded", loadApp);
}
