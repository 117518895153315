import React from "react";
import cn from "classnames";
import { useLocation } from "react-router-dom-v5-compat";
import { Box, Toolbar, Typography, Button, Divider } from "@mui/material";
import { useLocalStore, useStoreState } from "easy-peasy";
import { sessionsStore } from "../sessions/stores/sessionsStore";
import ButtonLink from "components/admin_v2/ui/ButtonLink";
import Navbar from "./Navbar";
import Envbar from "./Envbar";
import Logo from "images/4mativ-logo-white.svg";
import { pageRoutes } from "services/api";
import RouterLink from "components/admin_v2/nav/RouterLink";
import SchoolSelector from "./SchoolSelector";
import DistrictSelector from "./DistrictSelector";
import { useContainerStyles } from "components/admin_v2/useStyles";
import { useAppbarStyles } from "./useStyles";
import I18n from "utils/i18n.js";

const REDUCED_TOOLBAR_PATHNAMES = ["/trips"];

const Appbar = (_props) => {
  const cls = useAppbarStyles();
  const clsContainer = useContainerStyles();
  const { user, school } = useStoreState((s) => s.app);
  const { signOutUser } = useLocalStore(() => sessionsStore())[1];
  const location = useLocation();
  const reducedToolbar = REDUCED_TOOLBAR_PATHNAMES.some((p) => location.pathname.includes(p));

  const performSignOut = () => {
    signOutUser();
  };

  const toolbarCls = cn(clsContainer.mainGutterPadding, [
    cls.toolbar,
    { admin: user?.admin },
    { [cls.toolbarReduced]: reducedToolbar }
  ]);

  const logoCls = cn(cls.logo, { [cls.logoReduced]: reducedToolbar });

  const logo = user.admin ? (
    <RouterLink to={pageRoutes.search()}>
      <img src={Logo} alt="logo" className={logoCls} style={{ marginTop: "6px" }} />
    </RouterLink>
  ) : (
    <img src={Logo} alt="logo" className={logoCls} />
  );

  if (reducedToolbar)
    return (
      <>
        <Envbar />
        <Toolbar className={toolbarCls}>
          {logo}
          <div className={cls.toolbarContainerReduced}>
            <ButtonLink variant="text" to={pageRoutes.curbside()} className={cls.signOut}>
              {I18n.t("sessions.buttons.admin_home")}
            </ButtonLink>
            <Divider orientation="vertical" light={true} className={cls.dividerReduced} />
            <Button onClick={performSignOut} variant="text" className={cls.signOut}>
              {I18n.t("sessions.buttons.sign_out")}
            </Button>
          </div>
        </Toolbar>
      </>
    );

  return (
    <>
      <Envbar />
      <Toolbar className={toolbarCls}>
        <div className={cls.toolbarContainer}>
          {logo}
          <Divider orientation="vertical" light={true} className={cls.toolbarDivider} />
          {user.with_districts || user.with_schools ? (
            <Box className={cls.selectors}>
              {user.with_districts && <DistrictSelector />}
              <SchoolSelector />
            </Box>
          ) : (
            <Typography variant="h2" className={cls.toolbarTitle}>
              {school?.name}
            </Typography>
          )}
          <Button onClick={performSignOut} variant="text" className={cls.signOut}>
            {I18n.t("sessions.buttons.sign_out")}
          </Button>
        </div>
      </Toolbar>
      <Navbar />
    </>
  );
};

export default Appbar;
